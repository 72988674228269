import request from '@/api/lib/request';

export default {
  // 学生验证-列表(带分页)
  page(params) {
    return request.get('/enterprise/wxuserExamine/page', { params });
  },
  // 学生验证-审核
  examine(data) {
    return request.put('/enterprise/wxuserExamine/examine', data);
  },
};
