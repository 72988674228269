import request from '@/api/lib/request';

export default {
  // 举报类型-列表(带分页)
  page(params) {
    return request.get('/enterprise/accusation/page', { params });
  },
  // 举报类型-列表(无分页)
  list() {
    return request.get('/enterprise/accusation/page');
  },
  // 举报类型-创建
  add(data) {
    return request.post('/enterprise/accusation/add', data);
  },
  // 举报类型-删除
  del(id) {
    return request.delete('/enterprise/accusation/delete', { data: { id } });
  },
  // 举报类型-编辑
  edit(data) {
    return request.put('/enterprise/accusation/edit', data);
  },
};
