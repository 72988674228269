import request from '@/api/lib/request';

export default {
  // 学期学期课程设置-列表(带分页)
  page(params) {
    return request.get('/enterprise/courseSetting/page', { params });
  },
  // 学期课程设置-添加学期课程设置
  add(data) {
    return request.post('/enterprise/courseSetting/add', data);
  },
  // 学期课程设置-编辑
  edit(data) {
    return request.put('/enterprise/courseSetting/edit', data);
  },
  // 学期课程设置-确认
  confirm(data) {
    return request.put('/enterprise/courseSetting/confirm', data);
  },
  // 学期课程设置-详情
  detail(id) {
    return request.get('/enterprise/courseSetting/detail', { params: { id } });
  },
  // 学期课程设置-删除
  del(id) {
    return request.delete('/enterprise/courseSetting/delete', { data: id });
  },
};
