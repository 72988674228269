<template>
  <el-container class="main">
    <el-header class="header">
      <top-bar/>
    </el-header>
    <el-container>
      <el-aside class="menu">
        <main-menu/>
      </el-aside>
      <el-main class="content">
        <div class="content-inner-box">
          <div class="page-title mb-20">{{ $route.meta.title }}</div>
          <router-view/>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import TopBar from '@/components/TopBar.vue';
import MainMenu from '@/components/MainMenu.vue';

export default {
  name: 'Main',
  components: {
    MainMenu,
    TopBar,
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100vw;
  height: 100vh;
  background-color: #E5E9F2;

  .header {
    background-color: #324057;
  }

  .menu {
    width: 250px;
    background-color: #FFFFFF;
  }

  .content {
    width: calc(100vw - 200px);
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;

    .content-inner-box {
      padding: 20px;
      min-height: 100%;
      background-color: #FFFFFF;

      .page-title {
        font-size: 18px;
        font-weight: 700;
        color: #324057;
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.06);
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: #324057;
      -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
