<template>
  <div class="top-bar">
    <div class="title">
      <router-link to="/" style="color: #fff;text-decoration: unset">遇见青年校园平台</router-link>
    </div>
    <div class="toolbar">
      <el-dropdown @command="handleCommand">
        <div class="el-dropdown-link">
          <el-icon color="#fff" style="margin-right: 8px"><avatar /></el-icon>{{userInfo.username}}
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="detail">用户详情</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TopBar',
  created() {
    this.getUser();
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    getUser() {
      this.$api.user.profile().then(res => {
        // console.log(res);
        this.$store.state.userInfo = res.data
      })
    },
    handleCommand(e) {
      // console.log(e);
      if(e == 'logout') {
        this.$cookies.remove('token')
        location.reload()
      } else if(e == 'detail') {
        this.$router.push('/user-detail')
      }
    }
  }
};
</script>

<style scoped lang="scss">
.top-bar {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;

  .title {
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
  }
  .el-dropdown-link {
    color: #fff;
  }
}
</style>
