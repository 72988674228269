import request from '@/api/lib/request';

export default {
  // 帖子管理-列表(带分页)
  page(params) {
    return request.get('/enterprise/article/page', { params });
  },
  // 学期课程设置-详情
  detail(articleId) {
    return request.get('/enterprise/article/detail', { params: { article_id: articleId } });
  },
  talk(params) {
    return request.post('/enterprise/article/talk', params);
  },
  editTalk(params) {
    return request.put('/enterprise/article/editTalk', params);
  },
  delete(params) {
    return request.delete('/enterprise/article/delete', { params });
  },
};
