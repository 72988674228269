<template>
  <div class="pagination">
    <el-pagination
      v-model:currentPage="currentPage"
      :page-sizes="[10, 20, 30, 40, 50, 100]"
      v-model:page-size="pageSize"
      layout="total, sizes, prev, pager, next"
      :total="total"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 20,
    },
    total: {
      required: true,
      type: Number,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit('update:page', val);
      },
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit('update:limit', val);
      },
    },
  },
  methods: {
    handleSizeChange() {
      this.currentPage = 1;
      this.$emit('change');
    },
    handleCurrentChange() {
      this.$emit('change');
    },
  },

};
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: flex-end;
}
</style>
