import request from '@/api/lib/request';

export default {
  // 组织用户-登录
  login(data) {
    return request.post('/enterprise/user/login', data);
  },
  // 组织用户-退出登录
  logout(token) {
    return request.post('/enterprise/user/logout', { token });
  },
  // 组织用户-列表(带分页)
  index(params) {
    return request.get('/enterprise/user/index', { params });
  },
  // 组织用户-列表(无分页)
  list(token) {
    return request.get('/enterprise/user/list', { params: { token } });
  },
  // 组织用户-详情
  show(params) {
    return request.get('/enterprise/user/show', { params });
  },
  // 组织用户-当前用户信息
  profile(token) {
    return request.post('/enterprise/user/profile', { token });
  },
  // 组织用户-创建
  register(data) {
    return request.post('/enterprise/user/register', data);
  },
  // 组织用户-修改
  edit(data) {
    return request.put('/enterprise/user/edit', data);
  },
  // 组织用户-删除
  del(data) {
    return request.post('/enterprise/user/delete', data);
  },
  // 组织用户-账号设置
  modifySecret(data) {
    return request.post('/enterprise/user/modifySecret', data);
  },
};
