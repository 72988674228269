<template>
  <el-config-provider :locale="zhCn" size="large">
    <router-view/>
  </el-config-provider>
</template>

<script>
import zhCn from 'element-plus/lib/locale/lang/zh-cn';

export default {
  data() {
    return {
      zhCn,
    };
  },
};
</script>

<style lang="scss">
.mb-20 {
  margin-bottom: 20px;
}

.right-box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.el-form {
  .el-form-item__label {
    line-height: 40px;
  }

  .el-input {
    min-width: 207px !important;
  }
}
</style>
