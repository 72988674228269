<template>
  <div class="login">
    <div class="login-box">
      <div class="title">遇见青年校园平台</div>
      <el-form label-position="top">
        <el-form-item label="账号">
          <el-input placeholder="请输入账号" v-model="form.account"/>
        </el-form-item>
        <el-form-item label="密码">
          <el-input placeholder="请输入密码" v-model="form.password" show-password/>
        </el-form-item>
<!--        <div class="code-box">-->
<!--          <el-form-item label="验证码" class="code">-->
<!--            <el-input placeholder="请输入验证码"/>-->
<!--          </el-form-item>-->
<!--          <div class="img"></div>-->
<!--        </div>-->
      </el-form>
      <el-button type="primary" class="login-btn" @click="submit">登录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      form: {
        account: '',
        password: '',
      },
    };
  },
  methods: {
    submit() {
      this.$api.user.login(this.form).then((res) => {
        this.$cookies.set('token', res.data.token);
        localStorage.setItem('admin', res.data);
        this.$router.replace(this.$route.query.redirect || '/');
      });
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #324057;

  .login-box {
    width: 400px;
    padding: 30px;
    border-radius: 10px;
    background-color: #FFFFFF;

    .title {
      padding-bottom: 30px;
      text-align: center;
      font-size: 20px;
    }

    .code-box {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .code {
        width: 290px;
      }

      .img {
        margin-bottom: 22px;
        width: 100px;
        height: 40px;
        border-radius: 4px;
        background-color: cadetblue;
      }
    }

    .login-btn {
      width: 100%;
    }
  }
}
</style>
