import axios from 'axios';
import VueCookies from 'vue-cookies';
import { ElMessage } from 'element-plus';
import Vue from 'vue';

const request = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://admin.yjqn.club/api' : ''
});

request.interceptors.request.use(
  (config) => {
    config.headers.Authorizer = VueCookies.get('token') || ''
    config.headers.token = VueCookies.get('token') || ''
    return config;
  },
  (error) => {
    ElMessage.error('请求出错，请稍后再试');
    return Promise.reject(error);
  },
);

// 添加响应拦截器
request.interceptors.response.use(
  (response) => {
    if (response.data.code !== 201) {
      ElMessage.error(response.data.msg);
      return Promise.reject(response);
    }
    return response.data;
  },
  (error) => {
    ElMessage.error('服务器出错，请稍后再试');
    return Promise.reject(error);
  },
);

export default request;
