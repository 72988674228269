import request from '@/api/lib/request';

export default {
  // 二手闲置分类-列表(带分页)
  page(params) {
    return request.get('/enterprise/articleTransferCategory/page', { params });
  },
  // 二手闲置分类-列表(无分页)
  list() {
    return request.get('/enterprise/articleTransferCategory/list');
  },
  // 二手闲置分类-创建
  add(data) {
    return request.post('/enterprise/articleTransferCategory/add', data);
  },
  // 二手闲置分类-删除
  del(id) {
    return request.delete('/enterprise/articleTransferCategory/delete', { data: { id } });
  },
  // 二手闲置分类-编辑
  edit(data) {
    return request.put('/enterprise/articleTransferCategory/edit', data);
  },
};
