<template>
  <el-menu class="menu" router :default-active="$route.path">

    <el-sub-menu index="user">
      <template #title>
        <el-icon>
          <user/>
        </el-icon>
        <div class="menu-title">用户管理</div>
      </template>
      <el-menu-item index="/user">
        <el-icon>
          <user/>
        </el-icon>
        <div class="menu-title">用户列表</div>
      </el-menu-item>
      <el-menu-item index="/user-examine">
        <el-icon>
          <user/>
        </el-icon>
        <div class="menu-title">学生认证</div>
      </el-menu-item>
    </el-sub-menu>

    <el-sub-menu index="courses">
      <template #title>
        <el-icon>
          <collection />
        </el-icon>
        <div class="menu-title">课程表管理</div>
      </template>
      <el-menu-item index="/courses">
        <el-icon>
          <calendar/>
        </el-icon>
        <div class="menu-title">课程表列表</div>
      </el-menu-item>
      <el-menu-item index="/courses-years">
        <el-icon>
          <calendar/>
        </el-icon>
        <div class="menu-title">学年管理</div>
      </el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="social">
      <template #title>
        <el-icon>
          <avatar />
        </el-icon>
        <div class="menu-title">社交管理</div>
      </template>
      <el-menu-item index="/social">
        <div class="menu-title">社交帖子列表</div>
      </el-menu-item>
      <el-menu-item index="/social-topics">
        <div class="menu-title">社交话题管理</div>
      </el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="idle">
      <template #title>
        <el-icon>
          <goods/>
        </el-icon>
        <div class="menu-title">二手闲置管理</div>
      </template>
      <el-menu-item index="/idle">
        <div class="menu-title">二手闲置列表</div>
      </el-menu-item>
      <el-menu-item index="/idle-category">
        <div class="menu-title">闲置分类管理</div>
      </el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="errand">
      <template #title>
        <el-icon>
          <ship/>
        </el-icon>
        <div class="menu-title">跑腿管理</div>
      </template>
      <el-menu-item index="/errand">
        <div class="menu-title">跑腿列表</div>
      </el-menu-item>
<!--      <el-menu-item index="/ban-runner">-->
<!--        <div class="menu-title">封禁用户</div>-->
<!--      </el-menu-item>-->
    </el-sub-menu>
    <el-menu-item index="/lost-and-found">
      <el-icon>
        <iphone/>
      </el-icon>
      <div class="menu-title">失物招领管理</div>
    </el-menu-item>
    <el-menu-item index="/carpool">
      <el-icon>
        <van/>
      </el-icon>
      <div class="menu-title">拼车管理</div>
    </el-menu-item>

<!--    <el-menu-item index="/report-classfiy">-->
<!--      <el-icon>-->
<!--        <goods/>-->
<!--      </el-icon>-->
<!--      <div class="menu-title">举报分类管理</div>-->
<!--    </el-menu-item>-->
    <el-sub-menu index="report">
      <template #title>
        <el-icon>
          <goods/>
        </el-icon>
        <div class="menu-title">举报管理</div>
      </template>
      <el-menu-item index="/report">
        <div class="menu-title">举报列表</div>
      </el-menu-item>
      <el-menu-item index="/report-classfiy">
        <div class="menu-title">举报分类管理</div>
      </el-menu-item>
    </el-sub-menu>
    <el-menu-item index="/notice">
      <el-icon>
        <bell />
      </el-icon>
      <div class="menu-title">公告管理</div>
    </el-menu-item>
    <el-menu-item index="/other">
      <el-icon>
        <Warning></Warning>
      </el-icon>
      <div class="menu-title">其他设置</div>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: 'MainMenu',
};
</script>

<style scoped lang="scss">
.menu {
  border-right: none;

  .menu-title {
    font-size: 16px;
  }
}
</style>
