import request from '@/api/lib/request';

export default {
  // 帖子话题-列表(带分页)
  page(params) {
    return request.get('/enterprise/articleTalkTopic/page', { params });
  },
  // 帖子话题-列表(无分页)
  list() {
    return request.get('/enterprise/articleTalkTopic/list');
  },
  // 帖子话题-创建
  add(data) {
    return request.post('/enterprise/articleTalkTopic/add', data);
  },
  // 帖子话题-删除
  del(id) {
    return request.delete('/enterprise/articleTalkTopic/delete', { data: { id } });
  },
  // 帖子话题-编辑
  edit(data) {
    return request.put('/enterprise/articleTalkTopic/edit', data);
  },
};
