import request from '@/api/lib/request';

export default {
  // 帖子举报-列表(带分页)
  page(params) {
    return request.get('/enterprise/accusationArticle/page', { params });
  },
  operate(params) {
    return request.post('/enterprise/accusationArticle/operate', params);
  },

  noticePage(params) {
    return request.get('/enterprise/accusationArticle/noticePage', { params });
  },
  delete(params) {
    return request.delete('/enterprise/accusationArticle/delete', params);
  },
  notice(params) {
    return request.post('/enterprise/accusationArticle/notice', params);
  },
  edit(params) {
    return request.put('/enterprise/accusationArticle/edit', params);
  },
  detail(params) {
    return request.get('/enterprise/accusationArticle/detail', { params });
  },
};
