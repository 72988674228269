import request from '@/api/lib/request';

export default {
  // 微信用户-列表(带分页)
  page(params) {
    return request.get('/enterprise/wxuser/page', { params });
  },
  // 微信用户-更改状态
  edit(data) {
    return request.put('/enterprise/wxuser/updateStatus', data);
  },
  receiver(data) {
    return request.put('/enterprise/wxuser/receiverStatus', data);
  },
};
