import { createRouter, createWebHashHistory } from 'vue-router';

import Main from '@/views/Main.vue';
import Login from '@/views/Login.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Main',
    component: Main,
    redirect: '/user',
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: '/user',
        name: 'User',
        meta: { title: '用户列表' },
        component: () => import(/* webpackChunkName: "User" */ '@/views/User.vue'),
      },
      {
        path: '/user-examine',
        name: 'UserExamine',
        meta: { title: '学生认证' },
        component: () => import(/* webpackChunkName: "Courses" */ '@/views/UserExamine.vue'),
      },
      {
        path: '/courses',
        name: 'Courses',
        meta: { title: '课程表列表' },
        component: () => import(/* webpackChunkName: "Courses" */ '@/views/Courses.vue'),
      },
      {
        path: '/courses-years',
        name: 'CoursesYears',
        meta: { title: '学年管理' },
        component: () => import(/* webpackChunkName: "Courses" */ '@/views/CoursesYears.vue'),
      },
      {
        path: '/social',
        name: 'Social',
        meta: { title: '社交贴子' },
        component: () => import(/* webpackChunkName: "Social" */ '@/views/Social.vue'),
      },
      {
        path: '/social-topics',
        name: 'SocialTopics',
        meta: { title: '社交话题' },
        component: () => import(/* webpackChunkName: "SocialTopics" */ '@/views/SocialTopics.vue'),
      },
      {
        path: '/social-detail',
        name: 'SocialDetail',
        meta: { title: '' },
        component: () => import(/* webpackChunkName: "SocialTopics" */ '@/views/SocialDetail.vue'),
      },
      {
        path: '/report-classfiy',
        name: 'ReportClassfiy',
        meta: { title: '帖子举报分类' },
        component: () => import(/* webpackChunkName: "Carpool" */ '@/views/ReportClassfiy.vue'),
      },
      {
        path: '/report',
        name: 'Report',
        meta: { title: '帖子举报列表' },
        component: () => import(/* webpackChunkName: "Carpool" */ '@/views/Report.vue'),
      },
      {
        path: '/idle',
        name: 'Idle',
        meta: { title: '二手闲置' },
        component: () => import(/* webpackChunkName: "Idle" */ '@/views/Idle.vue'),
      },
      {
        path: '/idle-category',
        name: 'IdleCategory',
        meta: { title: '闲置分类' },
        component: () => import(/* webpackChunkName: "IdleCategory" */ '@/views/IdleCategory.vue'),
      },
      {
        path: '/errand',
        name: 'Errand',
        meta: { title: '跑腿列表' },
        component: () => import(/* webpackChunkName: "Errand" */ '@/views/Errand.vue'),
      },
      {
        path: '/ban-runner',
        name: 'BanRunner',
        meta: { title: '封禁用户' },
        component: () => import(/* webpackChunkName: "BanRunner" */ '@/views/BanRunner.vue'),
      },
      {
        path: '/lost-and-found',
        name: 'LostAndFound',
        meta: { title: '失误招领' },
        component: () => import(/* webpackChunkName: "LostAndFound" */ '@/views/LostAndFound.vue'),
      },
      {
        path: '/carpool',
        name: 'Carpool',
        meta: { title: '拼车管理' },
        component: () => import(/* webpackChunkName: "Carpool" */ '@/views/Carpool.vue'),
      },
      {
        path: '/other',
        name: 'Other',
        meta: { title: '其他设置' },
        component: () => import(/* webpackChunkName: "Carpool" */ '@/views/Other.vue'),
      },
      {
        path: '/user-detail',
        name: 'userDetail',
        meta: { title: '用户详情' },
        component: () => import(/* webpackChunkName: "Carpool" */ '@/views/UserDetail.vue'),
      },
      {
        path: '/notice',
        name: 'notice',
        meta: { title: '公告管理' },
        component: () => import(/* webpackChunkName: "Carpool" */ '@/views/Notice.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
