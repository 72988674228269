import request from '@/api/lib/request';

export default {
  // 组织用户-详情
  show(params) {
    return request.get('/enterprise/setting/show', { params });
  },
  // 组织用户-修改
  edit(data) {
    return request.post('/enterprise/setting/edit', data);
  },
};
