import request from '@/api/lib/request';

export default {
  // 课程表学年-列表(带分页)
  page(params) {
    return request.get('/enterprise/courseYears/page', { params });
  },
  // 课程表学年-列表(无分页)
  list() {
    return request.get('/enterprise/courseYears/list');
  },
  // 课程表学年-创建
  add(data) {
    return request.post('/enterprise/courseYears/add', data);
  },
  // 课程表学年-删除
  del(id) {
    return request.delete('/enterprise/courseYears/delete', { data: { id } });
  },
  // 课程表学年-编辑
  edit(data) {
    return request.put('/enterprise/courseYears/edit', data);
  },
};
