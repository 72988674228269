import request from '@/api/lib/request';

export default {
  // 系统消息-列表(带分页)
  page(params) {
    return request.get('/enterprise/systemMessage/page', { params });
  },
  // 系统消息-创建
  add(data) {
    return request.post('/enterprise/systemMessage/add', data);
  },
};
