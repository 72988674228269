import request from '@/api/lib/request';

export default {
  // 管理员-登录
  login(data) {
    return request.post('/admin/user/login', data);
  },
  // 管理员-退出登录
  logout() {
    return request.post('/admin/user/logout');
  },
  // 管理员-列表
  index(params) {
    return request.get('/admin/user/index', { params });
  },
  // 管理员-详情
  show(id) {
    return request.get('/admin/user/show', { params: { id } });
  },
  // 管理员-当前用户信息
  profile() {
    return request.post('/admin/user/profile');
  },
  // 管理员-创建
  create(data) {
    return request.post('/admin/user/create', data);
  },
  // 管理员-修改
  edit(data) {
    return request.put('/admin/user/edit', data);
  },
  // 管理员-删除
  del(id) {
    return request.post('/admin/user/delete', { id });
  },
};
