import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import * as ElIcons from '@element-plus/icons-vue';
import Pagination from '@/components/Pagination.vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import 'normalize.css';
import 'element-plus/dist/index.css';
import VueCookies from 'vue-cookies';

const api = {};
const allApi = require.context('./api', false, /\.js$/);
allApi.keys().forEach((key) => {
  api[key.replace(/(.*\/)*([^.]+).*/ig, '$2')] = allApi(key).default;
});
console.log(api);

const app = createApp(App);
app.config.globalProperties.$api = api;
app.config.globalProperties.$cookies = VueCookies;

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {  // 判断该路由是否需要登录权限
    if (VueCookies.get('token')) {  // 通过vuex state获取当前的token是否存在
      next();
    }
    else {
      next({
        path: '/login',
        query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  }
  else {
    next();
  }
})

app.use(store);
app.use(router);
app.use(ElementPlus);

Object.keys(ElIcons).forEach((key) => {
  app.component(key === 'Menu' ? 'IconMenu' : key, ElIcons[key]);
});

api.setting.show({
  key: 'official'
}).then(res => {
  store.state.globalData = res.data
})

app.component('Pagination', Pagination);

app.mount('#app');
