import request from '@/api/lib/request';

export default {
  // 组织-列表(带分页)
  index(params) {
    return request.get('/enterprise/enterprise/index', { params });
  },
  // 组织-列表(无分页)
  list() {
    return request.get('/enterprise/enterprise/list');
  },
  // 组织-创建
  register(data) {
    return request.post('/enterprise/enterprise/register', data);
  },
  // 组织-删除
  del(id) {
    return request.delete('/enterprise/enterprise/delete', { params: { id } });
  },
  // 组织-修改
  edit(data) {
    return request.put('/enterprise/enterprise/edit', data);
  },
  // 组织-详情
  show(articleId) {
    return request.get('/enterprise/enterprise/show', { params: { article_id: articleId } });
  },
};
